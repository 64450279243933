<template>
    <form autocomplete="off" class="">
        <div class="row gy-3">
            <div class="col-md-6">
                <label for="inputTitle">
                    Title <span class="text-danger small">*</span>
                </label>
                <input type="text" v-model="data.title"
                    :class="{ 'is-invalid': v$.data.title.$error }"
                    id="inputTitle" name="title" class="form-control"
                    placeholder="Enter title" />
                <div v-for="(item, index) in v$.data.title.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <label class="form-label">
                    Slug 
                </label>
                <input type="text" v-model="data.slug" class="form-control"
                    name="slug" :disabled="true" :class="{ 'is-invalid': v$.data.slug.$error }" placeholder="Enter slug" />
                <div v-for="(item, index) in v$.data.slug.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>      
            <div class="col-12">
                <vue-dropzone id="brandUploadDrop" ref="brandUploadDrop"
                :use-custom-slot="true" :maxFiles="1">
                <div class="needsclick m-0">
                    <span class="bx bx-cloud-upload fs-1"></span>
                    <h6> {{editMode?'Add file to update':'Brand image'}} (optional)</h6>
                    <span class="text-muted fs-13">
                        (Image file is recommended (100x100 pixels))
                    </span>
                </div>
                </vue-dropzone>
            </div>
            <div class="col-12">
                <div class="d-flex mt-3 justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="editMode? update() : create()"
                     type="button">{{editMode? 'Update':'Submit'}}</b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import VueDropzone from "../VueDropzone.vue";

const validateSlug = (value) => {
    if (!value) return true; 
    return /^\w+(?:-\w+)*$/.test(value);
};

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components:{
        VueDropzone,
    },
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            data: {
                title:"",
                slug:"",
            },
        }
    },
    watch: {
        'data.title': function (title){
            if(this.editMode) return;
            this.data.slug = this.$filters.slug(title)
        },
    },
    validations() {
        return {        
            data:{
                title: { required: required},            
                slug: {
                    required,
                    validateSlug: helpers.withMessage("Enter a valid slug format (alpha-numeric)", validateSlug),
                },           
            }
        }
    },
    methods:{
        create() {
            this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                let formData = new FormData();
                if(this.collectFiles().length){
                    formData.append('image_file', this.collectFiles()[0])
                }
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post('/brands/create', formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("brand/ADD_BRAND", response.data.data)
                        this.resetForm();
                    }
                })
            })
        },
        update(){
             this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                const formData = new FormData()
                if(this.collectFiles().length){
                    formData.append('image_file', this.collectFiles()[0])
                }
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/brands/${this.editItem.id}/update`,formData)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.$store.commit("brand/UPDATE_BRAND", response.data.data)
                            this.resetForm()
                        }
                    })
            
            })
        },
        collectFiles(){
            let arrafile = [];
            if(this.$refs.brandUploadDrop){
                arrafile = [...this.$refs.brandUploadDrop.getAcceptedFiles()];
            }
            return arrafile
        },
        resetForm(){
            this.v$.$reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        if(this.editMode){
            this.data = this.$filters.patchUpdatables(this.data, this.editItem)
        }
    }
}
</script>